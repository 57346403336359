import axios from 'axios';
import { WEB_API_URL, $apiWeb, $api, API_URL } from '../api';

export async function fetchLogin(user_id, password) {
  return $api.post('/token/', { user_id, password });
}

export async function fetchSimpleLogin(user_id, password) {
  return $api.post('/simple/token', { user_id, password });
}

export async function fetchSmsLogin(data) {
  return $api.post('/sms/verify', { ...data });
}

export async function userCheckFetch() {
  return $api.get('/users/me/');
}

export async function registraton(firstName, lastName, email, phone) {
  return $apiWeb.patch('/signup-step1', {
    first_name: firstName,
    last_name: lastName,
    email_address: email,
    phone_number: phone,
  });
}

export async function verifyPhone(otp, phone_number) {
  return $apiWeb.post('/signup-step2', { otp, phone_number });
}

export async function resendSms(number) {
  return $api.post('/resend_sms/', { number });
}

export async function regInfo() {
  return $api.get('/registration-info/');
}

export async function regAccount() {
  return $api.get('/registration-info/');
}

export async function regStepThree(
  professional_status_id,
  profession_id,
  academic_status_id,
  organization_id
) {
  return $apiWeb.post('/signup-step3', {
    professional_status_id,
    profession_id,
    academic_status_id,
    organization_id,
  });
}

export async function regPass(password1, password2) {
  return $apiWeb.patch('/set-psw', { password1, password2 });
}

const apiClere = axios.create({
  withCredentials: true,
  baseURL: WEB_API_URL,
  headers: {
    ApiKey: 'y',
    'Content-Type': 'application/json',
    Version:
      localStorage.getItem('api-web-version') === null
        ? setTimeout(() => localStorage.getItem('api-web-version'), 300)
        : localStorage.getItem('api-web-version'),
  },
});

const apiClereApi = axios.create({
  withCredentials: true,
  baseURL: API_URL,
  headers: {
    ApiKey: 'x',
    'Content-Type': 'application/json',
    Version:
      localStorage.getItem('api-version') === null
        ? setTimeout(() => localStorage.getItem('api-version'), 300)
        : localStorage.getItem('api-version'),
  },
});

export async function forgotPassEmail(email) {
  return apiClere.post('/forgot-pass-email', { email });
}

export async function forgotPassPhone(phone) {
  return apiClere.post('/forgot-pass-phone', { phone });
}

export async function forgotPassRecoverCode(otp, email, phone_number) {
  return apiClere.post('/verify-recover-code', { otp, email, phone_number });
}

export async function forgotPassSetPsw(
  otp,
  email,
  phone_number,
  password1,
  password2
) {
  return apiClere.post('/recovery-set-psw', {
    otp,
    email,
    phone_number,
    password1,
    password2,
  });
}

export async function dawnloadInfo() {
  return $apiWeb.get('/software');
}

export async function regStageInfo() {
  return $apiWeb.get('/registration_stage_info');
}

export async function totpCreate(secret, user_id) {
  return apiClereApi.post('/totp/create', { secret, user_id });
}

export async function setShowUserId() {
  return $apiWeb.post('/show_user_id', {});
}

export async function setAuthenticatorVerify(data) {
  return $api.post('/totp/verify', { ...data });
}

export async function setPhoneConnect(data) {
  return apiClereApi.post('/phone/connect', { ...data });
}

export async function setPassReset(user_id) {
  return apiClere.post('/pass/reset', { user_id });
}

export async function setResetVerify(data) {
  return apiClere.post('/pass/reset/verify', { ...data });
}

export async function setPassNew(data) {
  return apiClere.post('/pass/new', { ...data });
}
