import React from "react";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";
import { LoginForm } from "../../components/Forms/LoginForm/LoginForm";

export const Login = () => {
  const loginErrors = useSelector((state) => state.auth.error);
  const qrLink = useSelector((state) => state.auth.qrLink);
  
  return (
    <div className="container">
      <div className="row login__page__row">
        <LoginForm loginErrors={loginErrors} />
      </div>
      {qrLink && <QRCode value={qrLink} />}
    </div>
  );
};
