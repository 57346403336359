import { combineReducers } from "redux";
import authReducer from "./authReducer";
import recoverReducer from "./RecoverReducer";
import registrationReducer from "./RegistrationReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  registration: registrationReducer,
  recover: recoverReducer
});

export default rootReducer;
