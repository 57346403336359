import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setLogoutAction } from "../../redux/reducers/authReducer";

const Header = () => {
  // useSelector
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.auth.user);
  // useDispatch
  const dispatch = useDispatch();
  // CONSTS
  const imgUrl = "./logo.png";
  // HANDLERS
  const onLogout = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("secret");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("last_try");
    dispatch(setLogoutAction());
  };

  return (
    <header className="container-fluid">
      <div className="col-lg-12">
        <div className="row">
          <div className="logo">
            <Link to={auth.isAuth ? "/" : "/login"}>
              <img src={imgUrl} alt="SimHavk Logo" />
            </Link>
            {auth.isAuth && user.registration_step === 5 && user.show_user_id && (
              <button
                class="btn btn-primary recover__btn logout__btn"
                onClick={onLogout}
              >
                Logout
              </button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
