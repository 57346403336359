import React, { useState } from "react";
import "./Popup.css";

export default function Popup(data) {
  const [popup, setpopup] = useState(true);
  const show = popup && data.open;

  const togglepopup = () => {
    setpopup(!popup);
  };
  return (
    <>
      {show && (
        <div className="popup">
          <div onClick={togglepopup} className="overlay"></div>
          <div className="popup-content">
          <h2 className="close-popup" onClick={togglepopup}> X </h2>
            <p>
              {data.data_to_display}
            </p>
          </div>
        </div>
      )}
    </>
  );
}