import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field } from "formik";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import flags from "react-phone-number-input/flags";
import Eye from "../../Eye/Eye";
import { smsVerifyTC } from "../../../redux/reducers/authReducer";
import { setPhoneConnect } from "../../../api/services/authService";

const validate = (value) => {
  let errorMessage;
  if (!value) {
    errorMessage = "Required";
  }
  return errorMessage;
};

export const SmsForm = ({ loginErrors }) => {
  // useState
  const [passwordClick, setPasswordClick] = useState(false);
  const [phoneError, setPhoneError] = useState(null);
  // useSelector
  const phoneConnected = useSelector((state) => state.auth.phoneConnected);
  const [phoneCheck, setPhoneCheck] = useState(phoneConnected);

  // useDispatch
  const dispatch = useDispatch();
  console.log(phoneCheck);
  // Handlers
  const setPhoneConnectHandler = async (phone_number) => {
    try {
      const user_id = localStorage.getItem("user_id");
      const secret = localStorage.getItem("secret");
      localStorage.setItem("phone_number", phone_number);
      await setPhoneConnect({ secret, user_id, phone_number });
      setPhoneCheck(true);
      setPhoneError(null);
    } catch (e) {
      setPhoneError(e.response);
    }
  };

  return (
    <div className="col-lg-12 form__box sms-form">
      <h2 className="ta-center h2-sms-login">Login with SMS</h2>
      {phoneConnected === false && (
        <Formik
          initialValues={{
            phone: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.phone) {
              errors.phone = "Required";
            } else if (values.phone.length > 15) {
              errors.phone = "Too long number";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setPhoneConnectHandler(values.phone);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form className="login__form button-0" onSubmit={handleSubmit}>
              <div className="form__center">
                <div className="form-group sms">
                  <label htmlFor="phone" className="form-label">
                    Phone Number
                  </label>
                  <PhoneInput
                    type="phone"
                    name="phone"
                    flags={flags}
                    placeholder="Enter your phone number"
                    className={`form-control ${
                      errors.phone && touched.phone && errors.phone
                        ? "is-invalid"
                        : ""
                    }`}
                    onChange={(e) => setFieldValue("phone", e)}
                    // onBlur={handleBlur}
                    value={values.phone}
                  />
                  {/* <div className="form-text">Select your country code</div> */}
                  {errors.phone && touched.phone && errors.phone ? (
                    <div className="invalid-feedback">{errors.phone}</div>
                  ) : null}
                </div>
                <div className="btnbox sms">
                  <button
                    type="submit"
                    className="btn btn-primary form__btn no-margin"
                    disabled={isSubmitting}
                  >
                    Send SMS
                  </button>
                  {(phoneError && phoneError?.status === 400) ||
                  phoneError?.status === 401 ||
                  phoneError?.status === 403 ||
                  phoneError?.status === 404 ||
                  phoneError?.status === 500 ? (
                    <span className="red first">
                      Error, please check the entered number
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}

      <Formik
        initialValues={{
          code: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          // same shape as initial values
          dispatch(smsVerifyTC(values.code));
          setSubmitting(false);
        }}
      >
        {({ errors, touched, handleSubmit, isSubmitting }) => (
          <form className="login__form correct_login_form" onSubmit={handleSubmit}>
            <div className="form__center">
              <div className="form-group form-group-code">
                <label htmlFor="code" className="form-label">
                  SMS Code
                </label>
                <div className="with__eye">
                  <Field
                    name="code"
                    type={passwordClick ? "text" : "password"}
                    className={`form-control bigger-placeholder ${
                      errors.code && touched.code && errors.code
                        ? "is-invalid"
                        : ""
                    }`}
                    id="code"
                    placeholder="Enter the code received by SMS"
                    validate={validate}
                    disabled={phoneCheck === false}
                  />
                  <Eye
                    passwordClick={passwordClick}
                    setPasswordClick={setPasswordClick}
                  />
                </div>

                {errors.code && touched.code && errors.code ? (
                  <div className="invalid-feedback">{errors.code}</div>
                ) : null}
              </div>
              <span
                className="resend-sms-link"
                onClick={() => {
                  if (phoneCheck) {
                    const phone_number = localStorage.getItem("phone_number");
                    setPhoneConnectHandler(phone_number);
                  }
                }}
              >
                Didn’t recive SMS? Get a new one
              </span>
              <div className="btnbox sms bottom">
                <button
                  type="submit"
                  className="btn btn-primary form__btn"
                  disabled={phoneCheck === false}
                >
                  Login
                </button>
                {loginErrors.status === 400 ||
                loginErrors.status === 401 ||
                loginErrors.status === 403 ||
                loginErrors.status === 404 ||
                loginErrors.status === 500 ? (
                  <span className="red second">Wrong code</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
