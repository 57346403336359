import axios from 'axios';

export const API_URL = 'https://p.simhawk.tech/api/v1';
export const WEB_API_URL = 'https://p.simhawk.tech/web_api/v1';

export const $apiWeb = axios.create({
  withCredentials: true,
  baseURL: WEB_API_URL,
  headers: {
    ApiKey: 'y',
    'Content-Type': 'application/json',
    Version:
      localStorage.getItem('api-web-version') === null
        ? setTimeout(() => localStorage.getItem('api-web-version'), 300)
        : localStorage.getItem('api-web-version'),
  },
});

$apiWeb.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

export const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
  headers: {
    ApiKey: 'x',
    'Content-Type': 'application/json',
    Version:
      localStorage.getItem('api-version') === null
        ? setTimeout(() => localStorage.getItem('api-version'), 300)
        : localStorage.getItem('api-version'),
  },
});

$api.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
});
