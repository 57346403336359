import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { regStepFourTC } from "../../../../redux/reducers/RegistrationReducer";
import { useHistory } from "react-router-dom";
// import LeftArrow from "../../../LeftArrow/LeftArrow";
import Eye from "../../../Eye/Eye";
import { useEffect } from "react";

let firstValue = "";

const validate = (value) => {
  let errorMessage;
  if (!value) {
    errorMessage = "Required";
  } else if (value.length < 6) {
    errorMessage = "Too short code";
  } else if (!/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/g.test(value)) {
    errorMessage = " ";
  }
  firstValue = value;
  return errorMessage;
};

const validateSecondPsw = (value) => {
  let errorMessage;
  // console.log(value);
  if (!value) {
    errorMessage = "Required";
  } else if (firstValue !== value) {
    errorMessage = "Confirm Password is not correct";
  }
  return errorMessage;
};

const validateChack = (value) => {
  let errorMessage;
  if (value === false) {
    errorMessage = "Please agree with the Terms of service to register";
  }
  return errorMessage;
};

export const RegistrationFormFour = () => {
  const registration = useSelector((state) => state.registration);

  // useDispatch
  const dispatch = useDispatch();

  const history = useHistory();

  const [passwordClick, setPasswordClick] = useState(false);
  const [passwordTwoClick, setPasswordTwoClick] = useState(false);

  const regStepFour = (password1, password2) => {
    dispatch(regStepFourTC(password1, password2));
    history.push("/download");
  };

  useEffect(() => {
    document.querySelector(".container.with__dots .row.login__page__row").style.minHeight = "550px"
  }, [])

  return (
    <>
      <Formik
        initialValues={{
          password1: "",
          password2: "",
          check: false,
        }}
        onSubmit={(values, actions) => {
          regStepFour(values.password1, values.password2);
        }}
      >
        {({ errors, touched }) => (
          <Form className="col-lg-12 recover form-whith-eye form__box">
            <div>
              <div className="recover__header">
                <h2 className="with--arrow h2-create-password">
                  {/* <LeftArrow /> Create Password */}
                  Create Password
                </h2>
              </div>
              <div
                className="recover__sms marginblabel"
                style={{ marginBottom: "30px" }}
              >
                <span>SimHawk Password</span>
                <div className="with__eye with-form-text">
                  <Field
                    name="password1"
                    type={passwordClick ? "text" : "password"}
                    validate={validate}
                    placeholder="Enter your SimHawk password"
                    className={`form-control with-form-text ${errors.password1 && touched.password1 && errors.password1
                      ? "is-invalid mobile__with__mb"
                      : ""
                      }`}
                  />
                  <div className="form-text">
                    The password must contain 8 digits, upper and lower letters,
                    and numbers
                  </div>
                  <Eye
                    passwordClick={passwordClick}
                    setPasswordClick={setPasswordClick}
                  />
                </div>
                {errors.password1 && touched.password1 && errors.password1 ? (
                  <div className="invalid-feedback max__w">
                    {errors.password1}
                  </div>
                ) : null}
              </div>
              <div className="recover__sms marginblabel">
                <span>Confirm Password</span>
                <div className="with__eye">
                  <Field
                    name="password2"
                    type={passwordTwoClick ? "text" : "password"}
                    validate={validateSecondPsw}
                    placeholder="Re-enter your SimHawk password"
                    className={`form-control mb0 ${errors.password2 && touched.password2 && errors.password2
                      ? "is-invalid mobile__without__mb"
                      : ""
                      }`}
                  />
                  <Eye
                    passwordClick={passwordTwoClick}
                    setPasswordClick={setPasswordTwoClick}
                  />
                </div>
                {errors.password2 && touched.password2 && errors.password2 ? (
                  <div className="invalid-feedback pass_two_error">
                    {errors.password2}
                  </div>
                ) : null}
              </div>
              <div className="form-check recover__sms marginblabel formcheck">
                <Field
                  type="checkbox"
                  name="check"
                  validate={validateChack}
                  className={`form-check-input ${errors.check && touched.check && errors.check
                    ? "is-invalid"
                    : ""
                    }`}
                  id="exampleCheck1"
                />
                <label
                  className={`form-check-label ${errors.check && touched.check && errors.check
                    ? "is-invalid"
                    : ""
                    }`}
                  htmlFor="exampleCheck1"
                >
                  By creating an account, I agree to the{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.simhawk.net/terms-agreements"
                  >
                    Terms of Service and Privacy Policy
                  </a>
                </label>
              </div>
              {errors.check && touched.check && errors.check ? (
                <div className="invalid-feedback pass_two_error form-whith-eye">
                  {errors.check}
                </div>
              ) : null}
            </div>
            <div>
              <button type="submit" className="btn btn-primary recover__btn">
                Register
              </button>
              {registration.stepFourStatus === 400 ||
                registration.stepFourStatus === 403 ||
                registration.stepFourStatus === 404 ? (
                <span className="red">{registration.stepFour}</span>
              ) : (
                ""
              )}
              {registration.stepFourStatus === 200 ||
                registration.stepFourStatus === 201 ? (
                <span></span>
              ) : (
                ""
              )}
            </div>
          </Form>
        )}
      </Formik>
      <div
        className="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>
              <p>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>
              <p>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
