import axios from "axios";
import { API_URL } from "../../api/api";
import {
  fetchLogin,
  totpCreate,
  userCheckFetch,
  fetchSimpleLogin,
  fetchSmsLogin,
  setAuthenticatorVerify,
} from "../../api/services/authService";

//Action Types
const SET_LOGIN = "SET_LOGIN";
const SET_LOGOUT = "SET_LOGOUT";
const CHECKAUTH = "CHECKAUTH";
const ON_LOGIN_ERROR = "ON_LOGIN_ERROR";
const QR_LINK = "QR_LINK";
const SMS_LINK = "SMS_LINK";
const SMS_VERIFY = "SMS_VERIFY";
const AUTH_VERIFY = "AUTH_VERIFY";
const QR_SCANNED = "QR_SCANNED";

//initialState
let initialState = {
  user: [],
  isAuth: false,
  error: [],
  qrLink: null,
  qrToken: null,
  qrScanned: null,
  smsLink: null,
  phoneConnected: null,
};
//Reducer
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN:
      return {
        ...state,
        user: action.payload,
        isAuth: true,
        error: [],
      };
    case SET_LOGOUT:
      return {
        ...state,
        user: [],
        isAuth: false,
        error: [],
        qrLink: null,
        qrToken: null,
        qrScanned: null,
        smsLink: null,
        phoneConnected: null,
      };
    case CHECKAUTH:
      return {
        ...state,
        user: action.payload,
        isAuth: true,
      };
    case ON_LOGIN_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case QR_LINK:
      return {
        ...state,
        qrLink: action.payload.qr_link,
        qrToken: action.payload.token,
        error: [],
      };
    case SMS_LINK:
      return {
        ...state,
        smsLink: action.payload.authenticator,
        phoneConnected: action.payload.phoneConnected,
        error: [],
      };
    case SMS_VERIFY:
      return {
        ...state,
        user: action.payload,
        isAuth: true,
        error: [],
      };
    case AUTH_VERIFY:
      return {
        ...state,
        user: action.payload,
        isAuth: true,
        error: [],
      };
    case QR_SCANNED:
      return {
        ...state,
        qrScanned: action.payload,
        error: [],
      };
    default:
      return state;
  }
};

// Action Creators
export const setLogin = (payload) => ({
  type: SET_LOGIN,
  payload,
});
export const setLogoutAction = () => ({
  type: SET_LOGOUT,
});
export const setCheckAuth = (payload) => ({
  type: CHECKAUTH,
  payload,
});
export const onLoginError = (payload) => ({
  type: ON_LOGIN_ERROR,
  payload,
});
export const qrLinkAction = (payload) => ({
  type: QR_LINK,
  payload,
});
export const smsLinkAction = (payload) => ({
  type: SMS_LINK,
  payload,
});
export const smsVerifyAction = (payload) => ({
  type: SMS_VERIFY,
  payload,
});
export const setAuthVerifyAction = (payload) => ({
  type: AUTH_VERIFY,
  payload,
});
export const setQrScannedAction = (payload) => ({
  type: QR_SCANNED,
  payload,
});


// Redux Thunk Creator
export const loginTC = (email, password) => async (dispatch) => {
  try {
    let response = await fetchLogin(email, password);
    let payload = "";
    // simple Check
    const authenticator = response.data.authenticator;
    // Simple
    if (authenticator === "simple") {
      response = await fetchSimpleLogin(email, password);
      payload = response.data.user;
      if (response.data.access) {
        localStorage.setItem("token", response.data.access);
        localStorage.setItem("refreshToken", response.data.refresh);
        localStorage.setItem("last_try", 0);
        localStorage.removeItem("registration_phone");
        dispatch(setLogin(payload));
      }
      // SMS Login
    } else if (authenticator === "sms") {
      localStorage.setItem("user_id", response.data.user_id);
      localStorage.setItem("secret", response.data.secret);
      let phoneConnected = response.data.phone_connected;
      payload = { authenticator, phoneConnected };
      dispatch(smsLinkAction(payload));
      console.log("ToBeDone SMS Page");
      // MS Login
    } else if (authenticator === "ms") {
      localStorage.setItem("user_id", response.data.user_id);
      localStorage.setItem("secret", response.data.secret);
      if (response.data.secret && response.data.qr_scanned === false) {
        const res = await totpCreate(
          response.data.secret,
          response.data.user_id
        );
        payload = res.data;
        dispatch(qrLinkAction(payload));
      } else if (response.data.qr_scanned === true) {
        payload = response.data.qr_scanned;
        dispatch(setQrScannedAction(payload));
      }
    }
  } catch (e) {
    console.log(e);
    e.response["data"]["detail"] = "Wrong password entered or no active account found"
    const payload = e.response;
    dispatch(onLoginError(payload));
  }
};

export const smsVerifyTC = (otp) => async (dispatch) => {
  try {
    let payload = "";
    const user_id = localStorage.getItem("user_id");
    const secret = localStorage.getItem("secret");
    let phone_number = null;
    if (localStorage.getItem("phone_number")) {
      phone_number = localStorage.getItem("phone_number");
    }
    if (user_id !== undefined && secret !== undefined && otp !== undefined) {
      const response = await fetchSmsLogin({
        user_id,
        secret,
        otp,
        phone_number,
      });
      payload = response.data.user;
      localStorage.setItem("token", response.data.access);
      localStorage.setItem("refreshToken", response.data.refresh);
      localStorage.setItem("last_try", 0);
      localStorage.removeItem("registration_phone");
      dispatch(smsVerifyAction(payload));
    }
  } catch (e) {
    console.log(e);
    const payload = e.response;
    console.log(e.response);
    dispatch(onLoginError(payload));
  }
};

export const setAuthVerifyTC = (totp) => async (dispatch) => {
  try {
    let payload = "";
    const user_id = localStorage.getItem("user_id");
    const secret = localStorage.getItem("secret");

    if (user_id !== undefined && secret !== undefined && totp !== undefined) {
      const data = {
        user_id,
        secret,
        totp,
      };

      const response = await setAuthenticatorVerify(data);
      payload = response.data.user;
      localStorage.setItem("token", response.data.access);
      localStorage.setItem("refreshToken", response.data.refresh);
      localStorage.setItem("last_try", 0);
      localStorage.removeItem("registration_phone");
      dispatch(setAuthVerifyAction(payload));
    }
  } catch (e) {
    console.log(e);
    const payload = e.response;
    dispatch(onLoginError(payload));
  }
};

export const checkAuthTC = () => async (dispatch) => {
  try {
    const responseUser = await userCheckFetch();
    const payload = responseUser.data;
    // console.log("checkAuth", responseUser.data);

    const refresh = localStorage.getItem("refreshToken");
    var config = {
      method: "post",
      url: `${API_URL}/token/refresh/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { refresh: refresh },
    };
    const response = await axios(config);

    localStorage.setItem("token", response.data.access);
    localStorage.setItem("refreshToken", response.data.refresh);
    dispatch(setCheckAuth(payload));
  } catch (e) {
    // console.log(e);
  }
};

export default authReducer;
