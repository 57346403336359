import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import QRCode from "react-qr-code";
import { Formik, Field } from "formik";
import { setAuthVerifyTC } from "../../redux/reducers/authReducer";
import { BrowserView, MobileView } from "react-device-detect";

const validate = (value) => {
  let errorMessage;
  if (!value) {
    errorMessage = "Required";
  }
  return errorMessage;
};

export const QrCode = () => {
  const androidLogo = "./android-logo.png";
  const iosLogo = "./ios-logo.png";
  const appleQR = "./Apple_QR.jpeg.jpg";
  const androidQR = "./Android_QR.jpeg.jpg";
  const lodingSpinner = "./loding-spinner.gif";
  console.log("lodingSpinner", lodingSpinner);

  // useState
  const [qrStep, setQrStep] = useState(1);
  // const [qrStep1, setQrStep1] = useState("");
  // useSelector
  const qrLink = useSelector((state) => state.auth.qrLink);
  const qrToken = useSelector((state) => state.auth.qrToken);
  const qrScanned = useSelector((state) => state.auth.qrScanned);
  const loginErrors = useSelector((state) => state.auth.error);
  // useDispatch
  const dispatch = useDispatch();

  useEffect(() => {
    if (qrScanned) {
      setQrStep(3);
    }
    // eslint-disable-next-line
  }, []);

  console.log("loginErrors.status", loginErrors.status);

  return (
    <div className="sing_up_box">
      {qrStep === 1 && (
        <>
          <h1>SimHawk Sign Up</h1>

          <p className="configure-mobile-p">Download Microsoft Authenticator</p>
          <p className="configure-mobile-p">to your mobile phone by scanning</p>
          <p className="configure-mobile-p">One of these QR codes</p>
          <p className="configure-mobile-p-red">Please be aware that these QR codes are not to</p>
          <p className="configure-mobile-p-red">be used for logging into the SimHawk's software</p>
          <ul className="qr_box">
            <li>
              <img
                className="logo_img first"
                style={{ height: "28px", width: "auto" }}
                src={iosLogo}
                alt=""
              />
              <img
                className="qr_img"
                style={{ width: "100px", height: "auto" }}
                src={appleQR}
                alt=""
              />
            </li>
            <li>
              <img
                className="logo_img second"
                style={{
                  width: "100px",
                  height: "auto",
                }}
                src={androidLogo}
                alt=""
              />
              <img
                className="qr_img"
                style={{ width: "100px", height: "auto" }}
                src={androidQR}
                alt=""
              />
            </li>
          </ul>
        </>
      )}
      {qrStep === 1 && (
        <button
          className="btn btn-primary form__btn signup-btn"
          onClick={() => setQrStep(2)}
        >
          Next
        </button>
      )}
      {qrStep === 2 && qrLink && (
        <>
          <h1 className="big-page">Configure Mobile App</h1>

          <p className="configure-mobile-p left">
            Complete the following steps to configure your mobile app.
          </p>
          <ol className="configure-mobile-ol">
            <li>Install the Microsoft Authenticator app</li>
            <li>3
              In the app, add an account and choose “Work or school account”
            </li>
            <li><b>Please scan the QR code below:</b></li>
          </ol>
          {/* QRCode */}
          <BrowserView>
            <QRCode size={148} value={qrLink} />
          </BrowserView>
          <MobileView>
            <QRCode size={100} value={qrLink} />
          </MobileView>

          <ul className="configure-mobile-ul">
            <li>If you are unable to scan the QR code, please enter the following information in your app</li>
            <li className="configure-mobile-li-mt-top">
              <strong>Url:</strong> <span>{qrLink}</span>
            </li>
            <li className="configure-mobile-li-mt-top">
              <strong>Code:</strong> <span>{qrToken}</span>
            </li>
          </ul>
          <button
            className="btn btn-primary form__btn margin-5"
            style={{marginBottom: "15px"}}
            onClick={() => setQrStep(3)}
          >
            Next
          </button>
        </>
      )}
      {qrStep === 3 && (
        <div className="col-lg-12 form__box">
          <h1>Security Verification</h1>

          <p className="configure-mobile-p">
            Please confirm the notification on your device
          </p>
          <img className="loading-img" src={lodingSpinner} alt="loding" />
          <p className="configure-mobile-p">
            <strong>Enter Authenticator code</strong>
          </p>
          <Formik
            initialValues={{
              codeOne: "",
              codeTwo: "",
              codeThree: "",
              codeFour: "",
              codeFive: "",
              codeSix: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              // same shape as initial values
              const totp =
                values.codeOne +
                values.codeTwo +
                values.codeThree +
                values.codeFour +
                values.codeFive +
                values.codeSix;
              dispatch(setAuthVerifyTC(totp));
              //dispatch(smsVerifyTC(values.code));
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleSubmit,
              isSubmitting,
            }) => (
              <form className="login__form" onSubmit={handleSubmit}>
                <div className="form__center">
                  <div className="form-group inputs">
                    <div className="inputs-box">
                      <div className="inputs-inline">
                        <Field
                          name="codeOne"
                          type="text"
                          maxLength="1"
                          className={`form-control bigger-placeholder ${errors.codeOne && touched.codeOne && errors.codeOne
                              ? "is-invalid"
                              : ""
                            }`}
                          id="codeOne"
                          // onChange={(e) => {
                          //   values.codeOne = e.target.value;
                          // }}
                          onKeyDown={(e) => {
                            if (e.keyCode === 8) {
                              setFieldValue("codeOne", "");
                            } else if (
                              e.key === "1" ||
                              e.key === "2" ||
                              e.key === "3" ||
                              e.key === "4" ||
                              e.key === "5" ||
                              e.key === "6" ||
                              e.key === "7" ||
                              e.key === "8" ||
                              e.key === "9" ||
                              e.key === "0"
                            ) {
                              setFieldValue("codeOne", e.key);
                              setTimeout(() => {
                                document
                                  .querySelector(`input[name=codeTwo]`)
                                  .focus();
                              }, 50);
                            }
                            // else if (e.keyCode === 229) {
                            //   setTimeout(() => {
                            //     document
                            //       .querySelector(`input[name=codeTwo]`)
                            //       .focus();
                            //   }, 50);
                            // }
                          }}
                          validate={validate}
                        />
                      </div>

                      <div className="inputs-inline">
                        <Field
                          name="codeTwo"
                          type="text"
                          maxLength="1"
                          className={`form-control bigger-placeholder ${errors.codeTwo && touched.codeTwo && errors.codeTwo
                              ? "is-invalid"
                              : ""
                            }`}
                          id="codeTwo"
                          // onChange={(e) => {
                          //   values.codeTwo = e.target.value;
                          // }}
                          onKeyDown={(e) => {
                            if (e.keyCode === 8) {
                              setFieldValue("codeTwo", "");
                              setTimeout(() => {
                                document
                                  .querySelector(`input[name=codeOne]`)
                                  .focus();
                              }, 50);
                            } else if (
                              e.key === "1" ||
                              e.key === "2" ||
                              e.key === "3" ||
                              e.key === "4" ||
                              e.key === "5" ||
                              e.key === "6" ||
                              e.key === "7" ||
                              e.key === "8" ||
                              e.key === "9" ||
                              e.key === "0"
                            ) {
                              setFieldValue("codeTwo", e.key);
                              setTimeout(() => {
                                document
                                  .querySelector(`input[name=codeThree]`)
                                  .focus();
                              }, 50);
                            }
                            // else if (e.keyCode === 229) {
                            //   setTimeout(() => {
                            //     document
                            //       .querySelector(`input[name=codeThree]`)
                            //       .focus();
                            //   }, 50);
                            // }
                          }}
                          validate={validate}
                        />
                      </div>

                      <div className="inputs-inline">
                        <Field
                          name="codeThree"
                          type="text"
                          maxLength="1"
                          className={`form-control bigger-placeholder ${errors.codeThree &&
                              touched.codeThree &&
                              errors.codeThree
                              ? "is-invalid"
                              : ""
                            }`}
                          id="codeThree"
                          // onChange={(e) => {
                          //   values.codeThree = e.target.value;
                          // }}
                          onKeyDown={(e) => {
                            if (e.keyCode === 8) {
                              setFieldValue("codeThree", "");
                              setTimeout(() => {
                                document
                                  .querySelector(`input[name=codeTwo]`)
                                  .focus();
                              }, 50);
                            } else if (
                              e.key === "1" ||
                              e.key === "2" ||
                              e.key === "3" ||
                              e.key === "4" ||
                              e.key === "5" ||
                              e.key === "6" ||
                              e.key === "7" ||
                              e.key === "8" ||
                              e.key === "9" ||
                              e.key === "0"
                            ) {
                              setFieldValue("codeThree", e.key);
                              setTimeout(() => {
                                document
                                  .querySelector(`input[name=codeFour]`)
                                  .focus();
                              }, 50);
                            }
                            // else if (e.keyCode === 229) {
                            //   setTimeout(() => {
                            //     document
                            //       .querySelector(`input[name=codeFour]`)
                            //       .focus();
                            //   }, 50);
                            // }
                          }}
                          validate={validate}
                        />
                      </div>

                      <div className="inputs-inline">
                        <Field
                          name="codeFour"
                          type="text"
                          maxLength="1"
                          className={`form-control bigger-placeholder ${errors.codeFour &&
                              touched.codeFour &&
                              errors.codeFour
                              ? "is-invalid"
                              : ""
                            }`}
                          id="codeFour"
                          // onChange={(e) => {
                          //   values.codeFour = e.target.value;
                          // }}
                          onKeyDown={(e) => {
                            if (e.keyCode === 8) {
                              setFieldValue("codeFour", "");
                              setTimeout(() => {
                                document
                                  .querySelector(`input[name=codeThree]`)
                                  .focus();
                              }, 50);
                            } else if (
                              e.key === "1" ||
                              e.key === "2" ||
                              e.key === "3" ||
                              e.key === "4" ||
                              e.key === "5" ||
                              e.key === "6" ||
                              e.key === "7" ||
                              e.key === "8" ||
                              e.key === "9" ||
                              e.key === "0"
                            ) {
                              setFieldValue("codeFour", e.key);
                              setTimeout(() => {
                                document
                                  .querySelector(`input[name=codeFive]`)
                                  .focus();
                              }, 50);
                            }
                            // else if (e.keyCode === 229) {
                            //   setTimeout(() => {
                            //     document
                            //       .querySelector(`input[name=codeFive]`)
                            //       .focus();
                            //   }, 50);
                            // }
                          }}
                          validate={validate}
                        />
                      </div>

                      <div className="inputs-inline">
                        <Field
                          name="codeFive"
                          type="text"
                          maxLength="1"
                          className={`form-control bigger-placeholder ${errors.codeFive &&
                              touched.codeFive &&
                              errors.codeFive
                              ? "is-invalid"
                              : ""
                            }`}
                          id="codeFive"
                          // onChange={(e) => {
                          //   values.codeFive = e.target.value;
                          // }}
                          // omBlur={(e) => {
                          //   values.codeFive = e.target.value;
                          // }}
                          onKeyDown={(e) => {
                            // console.log(e);
                            if (e.keyCode === 8) {
                              setFieldValue("codeFive", "");
                              setTimeout(() => {
                                document
                                  .querySelector(`input[name=codeFour]`)
                                  .focus();
                              }, 50);
                            } else if (
                              e.key === "1" ||
                              e.key === "2" ||
                              e.key === "3" ||
                              e.key === "4" ||
                              e.key === "5" ||
                              e.key === "6" ||
                              e.key === "7" ||
                              e.key === "8" ||
                              e.key === "9" ||
                              e.key === "0"
                            ) {
                              setFieldValue("codeFive", e.key);
                              document
                                .querySelector(`input[name=codeSix]`)
                                .focus();
                            }
                            // else if (e.keyCode === 229 && e.key !== "Backspace") {
                            //   setTimeout(() => {
                            //     document
                            //       .querySelector(`input[name=codeSix]`)
                            //       .focus();
                            //   }, 2050);
                            // }
                          }}
                          validate={validate}
                        />
                      </div>

                      <div className="inputs-inline">
                        <Field
                          name="codeSix"
                          type="text"
                          maxLength="1"
                          className={`form-control bigger-placeholder ${errors.codeSix && touched.codeSix && errors.codeSix
                              ? "is-invalid"
                              : ""
                            }`}
                          id="codeSix"
                          onKeyDown={(e) => {
                            if (e.keyCode === 8) {
                              setFieldValue("codeSix", "");
                              setTimeout(() => {
                                document
                                  .querySelector(`input[name=codeFive]`)
                                  .focus();
                              }, 50);
                            } else if (
                              e.key === "1" ||
                              e.key === "2" ||
                              e.key === "3" ||
                              e.key === "4" ||
                              e.key === "5" ||
                              e.key === "6" ||
                              e.key === "7" ||
                              e.key === "8" ||
                              e.key === "9" ||
                              e.key === "0"
                            ) {
                              setFieldValue("codeSix", e.key);
                            }
                          }}
                          validate={validate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="btnbox">
                    <button
                      type="submit"
                      className="btn btn-primary form__btn margin-20"
                      style={{ marginTop: "22px" }}
                      disabled={isSubmitting}
                    >
                      Next
                    </button>
                    {loginErrors.status === 400 ||
                      loginErrors.status === 401 ||
                      loginErrors.status === 403 ||
                      loginErrors.status === 404 ? (
                      <span className="red">Wrong code</span>
                    ) : (
                      ""
                    )}
                    {loginErrors.status === 200 ||
                      loginErrors.status === 201 ? (
                      <span className="green">Success</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};
