import React from "react";
import { useSelector } from "react-redux";
import { SmsForm } from "../../components/Forms/SmsForm/SmsForm";

export const Sms = () => {
  // useSelector
  const loginErrors = useSelector((state) => state.auth.error);
  
  return (
    <div className="container">
      <div className="row login__page__row">
        <SmsForm loginErrors={loginErrors} />
      </div>
    </div>
  );
};
