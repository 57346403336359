import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginTC } from "../../../redux/reducers/authReducer";
import { Formik, Field } from "formik";
import { Link, useHistory } from "react-router-dom";
import Eye from "../../Eye/Eye";
import { } from "react-router-dom";

const validateChack = (value) => {
  let errorMessage;
  if (value === false) {
    errorMessage = "Please agree with the Terms of service";
  }
  return errorMessage;
};

export const LoginForm = ({ loginErrors }) => {
  // useHistory
  const history = useHistory();
  // useSelector
  const user = useSelector((state) => state.auth.user);
  // useDispatch
  const dispatch = useDispatch();
  const [passwordClick, setPasswordClick] = useState(false);

  const auth = (email, password) => {
    history.push("/");
    dispatch(loginTC(email, password));
  };

  return (
    <div className="col-lg-12 form__box">
      {user.gender}
      <Formik
        initialValues={{ email: "", password: "", check: false }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          }
          // else if (
          //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          // ) {
          //   errors.email = "Invalid email address";
          // }

          if (!values.password) {
            errors.password = "Required";
          } else if (values.password.length < 5) {
            errors.password = "Minimum password length 5 symbols";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          auth(values.email, values.password);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form className="login__form login" onSubmit={handleSubmit}>
            <div>
              <h2 className="h2-login">SimHawk Login</h2>
              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  User ID
                </label>
                <input
                  type="text"
                  name="email"
                  placeholder="Enter your user ID"
                  className={`form-control bigger-placeholder ${errors.email && touched.email && errors.email
                    ? "is-invalid"
                    : ""
                    }`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && touched.email && errors.email ? (
                  <div className="invalid-feedback">{errors.email}</div>
                ) : null}
              </div>

              <div className="form-group ">
                <label htmlFor="LoginPassword" className="form-label">
                  Password
                </label>
                <div className="with__eye">
                  <input
                    type={passwordClick ? "text" : "password"}
                    name="password"
                    placeholder="Enter your password"
                    className={`form-control bigger-placeholder ${errors.password && touched.password && errors.password
                      ? "is-invalid"
                      : ""
                      }`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <Eye
                    passwordClick={passwordClick}
                    setPasswordClick={setPasswordClick}
                  />
                </div>
                {errors.password && touched.password && errors.password ? (
                  <div className="invalid-feedback">{errors.password}</div>
                ) : null}
              </div>
              {/* Forgot Password Link */}
              <Link to="/recover" className="forgot_pass_link">
                Forgot Password?
              </Link>
              <div className="form-check recover__sms marginblabel formcheck margin-0">
                <Field
                  type="checkbox"
                  name="check"
                  validate={validateChack}
                  className={`form-check-input check ${errors.check && touched.check && errors.check
                    ? "is-invalid"
                    : ""
                    }`}
                  id="exampleCheck1"
                />
                <label
                  className={`form-check-label ${errors.check && touched.check && errors.check
                    ? "is-invalid"
                    : ""
                    }`}
                  htmlFor="exampleCheck1"
                >
                  I agree to the{" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.simhawk.net/terms-agreements"
                  >
                    Terms of Service and Privacy Policy
                  </a>
                </label>
              </div>
              {errors.check && touched.check && errors.check ? (
                <div className="invalid-feedback pass_two_error form-whith-eye">
                  {errors.check}
                </div>
              ) : null}
            </div>

            <div className="btnbox login">
              <button
                type="submit"
                className="btn btn-primary form__btn"
                disabled={isSubmitting}
              >
                Login
              </button>
              {loginErrors.status === 400 ||
                loginErrors.status === 401 ||
                loginErrors.status === 403 ||
                loginErrors.status === 404 ? (
                <span className="red">{loginErrors.data.detail}</span>
              ) : (
                ""
              )}
              {loginErrors.status === 200 || loginErrors.status === 201 ? (
                <span className="green">Success</span>
              ) : (
                ""
              )}
            </div>
          </form>
        )}
      </Formik>
      <p className="login-p">
        We are here to answer any questions or inquires you may have about SimHawk experiences.
        <br></br>{" "}
        <a href="mailto:support@simhawk.tech" style={{
          fontSize: "18px",
          textDecoration: "none"
        }}>support@simhawk.tech</a>
      </p>
      {/* <div
        className="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>
              <p>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>
              <p>
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum."
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div >
  );
};
