import React, { useEffect, useState } from "react";
import { dawnloadInfo } from "../../api/services/authService";
import { BrowserView, MobileView } from "react-device-detect";

const Download = () => {
  const [linkData, setlinkData] = useState(null);

  useEffect(() => {
    dawnloadInfo().then((res) => {
      try {
        setlinkData(res.data);
      } catch (e) {
        console.log(e);
      }
    });
  }, []);

  let OSName = "Unknown OS";
  if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
  if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
  if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
  if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";
  // console.log(OSName);

  // console.log(linkData);

  // const rightImgSrc = "./RightDownload.png";
  const mobileDawnloadImgSrc = "./mobile-dawnload.png";
  const appleImgSrc = "./apple-icon.png";
  const windowImgSrc = "./windows-icon.png";

  return (
    <>
      <BrowserView>
        {linkData !== null && (
          <div className="container">
            <div className="row login__page__row">
              <div className="col-lg-12 recover dawnload__center">
                {OSName === "MacOS" ? (
                  <>
                    <div>
                      <div className="recover__header dawnload">
                        <h2 className="h2-40">Download Software</h2>
                      </div>

                      <div className="recover__dawnload">
                        <div>
                          <h4>
                            Download for macOS{" "}
                            <img
                              className="img--icon ios"
                              src={appleImgSrc}
                              alt=""
                            />
                          </h4>
                          <span>
                            This version enables you access to SimHawk software
                          </span>
                        </div>
                        <a
                          href={linkData.macos}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary recover__btn"
                        >
                          Download
                        </a>
                      </div>
                    </div>
                    <div className="second-dawnload-box">
                      <div className="recover__dawnload">
                        <div>
                          <h4>
                            Download for Windows{" "}
                            <img
                              className="img--icon win"
                              src={windowImgSrc}
                              alt=""
                            />
                          </h4>
                          <span>
                            This version enables you access to SimHawk software
                          </span>
                        </div>
                        <a
                          href={linkData.windows}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary recover__btn"
                        >
                          Download
                        </a>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <div className="recover__header dawnload">
                        <h2 className="h2-40">Download Software</h2>
                      </div>
                      <div className="recover__dawnload">
                        <div>
                          <h4>
                            Download for Windows{" "}
                            <img
                              className="img--icon win"
                              src={windowImgSrc}
                              alt=""
                            />
                          </h4>
                          <span>
                            This version enables you access to SimHawk software
                          </span>
                        </div>
                        <a
                          href={linkData.windows}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary recover__btn"
                        >
                          Download
                        </a>
                      </div>
                    </div>
                    <div className="second-dawnload-box">
                      <div className="recover__dawnload">
                        <div>
                          <h4>
                            Download for macOS{" "}
                            <img
                              className="img--icon ios"
                              src={appleImgSrc}
                              alt=""
                            />
                          </h4>
                          <span>
                            This version enables you access to SimHawk software
                          </span>
                        </div>
                        <a
                          href={linkData.macos}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-primary recover__btn"
                        >
                          Download
                        </a>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* <div className="col-lg-6">
                <div className="right__image">
                  <img src={rightImgSrc} alt="" />
                </div>
              </div> */}
            </div>
          </div>
        )}
      </BrowserView>
      <MobileView>
        <div className="container">
          <div className="row login__page__row">
            <div className="col-lg-6 recover">
              <div>
                <div>
                  <div className="recover__header">
                    <h2 className="h2-mobile-dawnload">Download Software</h2>
                    <p className="mobile__text">
                      Dear SimHawk User, <i className="download-br" />
                      In order to download the software and enjoy our service,
                      you must enter this link through your desktop:{" "}
                      <a
                        className="download-link"
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.simhawk.net/terms-agreements"
                      >
                        www.simhawk.com/download
                      </a>
                    </p>
                  </div>
                </div>
                <div>
                  <img
                    className="mobile__image"
                    src={mobileDawnloadImgSrc}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MobileView>
    </>
  );
};

export default Download;
