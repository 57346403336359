import React from "react";
import { 
  useDispatch, 
  useSelector 
} from "react-redux";
import { setShowUserId } from "../../api/services/authService";
import { checkAuthTC } from "../../redux/reducers/authReducer";


export const UserId = () => {
  // useSelector
  const auth = useSelector((state) => state.auth);
  // useDispatch
  const dispatch = useDispatch();
  
  const setUserIdHandler = async () => {
    try {
      await setShowUserId();
      dispatch(checkAuthTC());
      // await 
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="container">
      <div className="row login__page__row">
        <div className="col-lg-12 form__box">
          <div className="login__form">
            <div className="form__center">
              <h2 className="h2-45">Save Your User ID</h2>
              <p className="p-center first">Dear SimHawk User,</p>
              <p className="p-center">Your User ID <strong>{auth?.user?.user_id}</strong> is personal. In order to protect your privacy, please take a screenshot of this page with your User ID, or take a photo of your User ID placed on your SimHawk kit, and keep it somewhere safe.</p>
              <button  
                className="btn btn-primary form__btn user-id-button" 
                onClick={() => setUserIdHandler()}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
