import React, { 
  useEffect, 
  // useState 
} from "react";
// import { RegistrationFormOne } from "../../components/Forms/RegistrationForm/RegistrationFormOne/RegistrationFormOne";
// import { RegistrationFormTwo } from "../../components/Forms/RegistrationForm/RegistrationFormTwo/RegistrationFormTwo";
// import { RegistrationFormThree } from "../../components/Forms/RegistrationForm/RegistrationFormThree/RegistrationFormThree";
import { RegistrationFormFour } from "../../components/Forms/RegistrationForm/RegistrationFormFour/RegistrationFormFour";
import { useDispatch, useSelector } from "react-redux";
import {
  regStageInfoTC,
  setLoadingStepAction,
  // setStepAction,
} from "../../redux/reducers/RegistrationReducer";

const Registration = ({ userInfo, startStep }) => {
  // const rightImgSrc1 = "./Right2.png";
  // const rightImgSrc2 = "./RightSetUpAccount.png";
  // const rightImgSrc3 = "./RightCreatePassword.png";

  // const [step, setstep] = useState(1);
  const step = useSelector((state) => state.registration.step);
  // const stepNow = useSelector((state) => state.registration.stepNow);
  // const regData = useSelector((state) => state.registration);

  // useDispatch
  const dispatch = useDispatch();
  
  // const [verifyPhone, setVerifyPhone] = useState("");

  // const verifyPhoneCallback = (phone_number) => {
  //   setVerifyPhone(phone_number);
  // };

  // const setstep = (payload) => {
  //   dispatch(setStepAction(payload));
  // };

  useEffect(() => {
    dispatch(setLoadingStepAction(startStep));
    dispatch(regStageInfoTC());
    // eslint-disable-next-line
  }, []);

  const stepContainer =
    // step === 1 ? (
    //   <RegistrationFormOne
    //     verifyPhoneCallback={verifyPhoneCallback}
    //     userInfo={userInfo}
    //   />
    // ) : step === 2 ? (
    //   <RegistrationFormTwo
    //     verifyPhone={verifyPhone}
    //     verifyPhoneCallback={verifyPhoneCallback}
    //   />
    // ) : step === 3 ? (
    //   <RegistrationFormThree />
    // ) : step === 4 ? (
    //   <RegistrationFormFour />
    // ) : (
    //   ""
    // );

    step <= 4 ? (
      <RegistrationFormFour />
    ) : (
      ""
    );

  return (
    <div className="container with__dots">
      <div className="row login__page__row mobile-width">
        {stepContainer}
        {/* <div className="col-lg-6">
          <div className="right__image">
            {step === 1 ? (
              <img src={rightImgSrc1} alt="" />
            ) : step === 2 ? (
              <img src={rightImgSrc1} alt="" />
            ) : step === 3 ? (
              <img src={rightImgSrc2} alt="" />
            ) : step === 4 ? (
              <img src={rightImgSrc3} alt="" />
            ) : (
              ""
            )}
          </div>
        </div> */}
      </div>

      {/*<div className="row">
        <div className="col-lg-12">
          <div className="center withdots">
            <div className="dots">
              <span
                onClick={() => setstep(1)}
                className={`dot dot-lg ${step === 1 ? "" : "dot-white"}`}
              ></span>
              <span
                onClick={() => {
                  if (stepNow >= 2) setstep(2);
                }}
                className={`dot dot-lg ${step === 2 ? "" : "dot-white"}`}
              ></span>
              <span
                onClick={() => {
                  if (stepNow >= 3) setstep(3);
                }}
                className={`dot dot-lg ${step === 3 ? "" : "dot-white"}`}
              ></span>
              <span
                onClick={() => {
                  if (stepNow >= 4) setstep(4);
                }}
                className={`dot dot-lg ${step === 4 ? "" : "dot-white"}`}
              ></span>
            </div>
          </div>
        </div>
      </div>*/}
    </div>
  );
};

export default Registration;
