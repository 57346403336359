import {
  registraton,
  regPass,
  regStageInfo,
  regStepThree,
  verifyPhone,
} from "../../api/services/authService";
import { checkAuthTC } from "./authReducer";

//Action Types
const REGISTRATION_SET_STEP = "REGISTRATION_SET_STEP";
const REGISTRATION_LOADING_SET_STEP = "REGISTRATION_LOADING_SET_STEP";
const REGISTRATION_STEP_ONE = "REGISTRATION_STEP_ONE";
const REGISTRATION_STEP_TWO = "REGISTRATION_STEP_TWO";
const REGISTRATION_STEP_THREE = "REGISTRATION_STEP_THREE";
const REGISTRATION_STEP_FOUR = "REGISTRATION_STEP_FOUR";
const REGISTRATION_STAGE_INFO = "REGISTRATION_STAGE_INFO";

//initialState
let initialState = {
  step: 1,
  stepNow: 1,
  stepOne: [],
  stepOneStatus: "",
  stepTwo: [],
  stepTwoStatus: "",
  stepThree: [],
  stepThreeStatus: "",
  stepFour: [],
  stepFourStatus: "",
  stageInfo: [],
  sendSmsErrorCounter: 0,
};
//Reducer
const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTRATION_SET_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case REGISTRATION_LOADING_SET_STEP:
      return {
        ...state,
        step: action.payload,
        stepNow: action.payload,
      };
    case REGISTRATION_STEP_ONE:
      return {
        ...state,
        step: action.payload.step,
        stepNow: action.payload.step,
        stepOne: action.payload.data,
        stepOneStatus: action.payload.status,
      };
    case REGISTRATION_STEP_TWO:
      return {
        ...state,
        step: action.payload.step,
        stepNow: action.payload.step,
        stepTwo: action.payload.data,
        stepTwoStatus: action.payload.status,
        sendSmsErrorCounter: action.payload.sendSmsErrorCounter,
      };
    case REGISTRATION_STEP_THREE:
      return {
        ...state,
        step: action.payload.step,
        stepNow: action.payload.step,
        stepThree: action.payload.data,
        stepThreeStatus: action.payload.status,
      };
    case REGISTRATION_STEP_FOUR:
      return {
        ...state,
        step: action.payload.step,
        stepNow: action.payload.step,
        stepFour: action.payload.data,
        stepFourStatus: action.payload.status,
      };
    case REGISTRATION_STAGE_INFO:
      return {
        ...state,
        stageInfo: action.payload,
        stepNow: action.payload.registration_step,
      };
    default:
      return state;
  }
};

// Action Creators
export const setStepAction = (payload) => ({
  type: REGISTRATION_SET_STEP,
  payload,
});
export const setLoadingStepAction = (payload) => ({
  type: REGISTRATION_LOADING_SET_STEP,
  payload,
});
export const stepOneAction = (payload) => ({
  type: REGISTRATION_STEP_ONE,
  payload,
});
export const stepTwoAction = (payload) => ({
  type: REGISTRATION_STEP_TWO,
  payload,
});
export const stepThreeAction = (payload) => ({
  type: REGISTRATION_STEP_THREE,
  payload,
});
export const stepFourAction = (payload) => ({
  type: REGISTRATION_STEP_FOUR,
  payload,
});
export const setStageInfoAction = (payload) => ({
  type: REGISTRATION_STAGE_INFO,
  payload,
});

// Redux Thunk Creator
export const regStepOneTC =
  (firstName, lastName, email, phone) => async (dispatch) => {
    try {
      if (phone[0] !== "+") {
        phone = "+" + phone;
      }
      localStorage.setItem("registration_phone", phone);
      let payload = await registraton(firstName, lastName, email, phone);
      payload.step = 2;
      dispatch(stepOneAction(payload));
    } catch (e) {
      e.response.step = 1;
      console.log(e);
      dispatch(stepOneAction(e.response));
    }
  };

export const regStepTwoTC =
  (otp, phone_number, send_sms_errs) => async (dispatch) => {
    try {
      phone_number = localStorage.getItem("registration_phone");
      if (phone_number[0] !== "+") {
        phone_number = "+" + phone_number;
      }

      let payload = await verifyPhone(otp, phone_number);
      payload.step = 3;
      payload.sendSmsErrorCounter = send_sms_errs;
      localStorage.setItem("last_try", 0);
      dispatch(stepTwoAction(payload));
    } catch (e) {
      let last_try = localStorage.getItem("last_try");
      localStorage.setItem("last_try", ++last_try);
      let last_try_next = localStorage.getItem("last_try");

      e.response.step = 2;
      e.response.sendSmsErrorCounter = last_try_next;

      console.log(e);
      dispatch(stepTwoAction(e.response));
    }
  };

export const regStepThreeTC =
  (
    professional_status_id,
    profession_id,
    academic_status_id,
    organization_id
  ) =>
  async (dispatch) => {
    try {
      let payload = await regStepThree(
        professional_status_id,
        profession_id,
        academic_status_id,
        organization_id
      );
      payload.step = 4;
      dispatch(stepThreeAction(payload));
    } catch (e) {
      e.response.step = 3;
      console.log(e);
      dispatch(stepThreeAction(e.response));
    }
  };

export const regStepFourTC = (password1, password2) => async (dispatch) => {
  try {
    let payload = await regPass(password1, password2);
    dispatch(stepFourAction(payload));
    dispatch(checkAuthTC());
  } catch (e) {
    console.log(e);
  }
};

export const regStageInfoTC = () => async (dispatch) => {
  try {
    let payload = await regStageInfo();
    console.log("payload", payload.data);
    dispatch(setStageInfoAction(payload.data));
  } catch (e) {
    console.log(e);
  }
};

export default registrationReducer;
