import {
  forgotPassEmail,
  forgotPassPhone,
  forgotPassRecoverCode,
  forgotPassSetPsw,
} from "../../api/services/authService";

//Action Types
const RECOVER_SET_STEP = "RECOVER_SET_STEP";
const RECOVER_STEP_ONE_MAIL = "RECOVER_STEP_ONE_MAIL";
const RECOVER_STEP_ONE_PHONE = "RECOVER_STEP_ONE_PHONE";
const RECOVER_STEP_THREE_CODE = "RECOVER_STEP_THREE_CODE";
const RECOVER_STEP_FOUR_SETPSW = "RECOVER_STEP_FOUR_SETPSW";

//initialState
let initialState = {
  step: 3,
  forgotStepOne: [],
  stepOneStatus: "",
  forgotStepTwo: [],
  stepTwoStatus: "",
  forgotStepThree: [],
  stepThreeStatus: "",
  forgotStepFour: [],
  stepFourStatus: "",
};
//Reducer
const recoverReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECOVER_SET_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case RECOVER_STEP_ONE_MAIL:
      return {
        ...state,
        step: action.payload.step,
        forgotStepTwo: action.payload.data,
        stepTwoStatus: action.payload.status,
      };
    case RECOVER_STEP_ONE_PHONE:
      return {
        ...state,
        step: action.payload.step,
        forgotStepTwo: action.payload.data,
        stepTwoStatus: action.payload.status,
      };
    case RECOVER_STEP_THREE_CODE:
      return {
        ...state,
        step: action.payload.step,
        forgotStepThree: action.payload.data,
        stepThreeStatus: action.payload.status,
      };
    case RECOVER_STEP_FOUR_SETPSW:
      return {
        ...state,
        forgotStepFour: action.payload.data,
        stepFourStatus: action.payload.status,
      };
    default:
      return state;
  }
};

// Action Creators
export const reSetStepAction = (payload) => ({
  type: RECOVER_SET_STEP,
  payload,
});
export const reStepOneEmailAction = (payload) => ({
  type: RECOVER_STEP_ONE_MAIL,
  payload,
});
export const reStepOnePhoneAction = (payload) => ({
  type: RECOVER_STEP_ONE_PHONE,
  payload,
});
export const reStepThreeCodeAction = (payload) => ({
  type: RECOVER_STEP_THREE_CODE,
  payload,
});
export const reStepFourSetPswAction = (payload) => ({
  type: RECOVER_STEP_FOUR_SETPSW,
  payload,
});

// Redux Thunk Creator
export const reStepOneEmailTC = (email) => async (dispatch) => {
  try {
    const payload = await forgotPassEmail(email);
    payload.step = 3;
    // console.log("payload", payload);
    dispatch(reStepOneEmailAction(payload));
  } catch (e) {
    e.response.step = 2;
    console.log(e);
    dispatch(reStepOneEmailAction(e.response));
  }
};

export const reStepOnePhoneTC = (phone) => async (dispatch) => {
  try {
    if (phone[0] !== "+") {
      phone = "+" + phone;
    }
    const payload = await forgotPassPhone(phone);
    payload.step = 3;
    // console.log("payload", payload);
    dispatch(reStepOnePhoneAction(payload));
  } catch (e) {
    e.response.step = 2;
    console.log(e);
    dispatch(reStepOnePhoneAction(e.response));
  }
};

export const recoverCodeTC = (otp, email, phone_number) => async (dispatch) => {
  try {
    if (phone_number[0] !== "+") {
      phone_number = "+" + phone_number;
    }
    const payload = await forgotPassRecoverCode(otp, email, phone_number);
    payload.step = 4;
    // console.log("payload", payload);
    dispatch(reStepThreeCodeAction(payload));
  } catch (e) {
    e.response.step = 3;
    console.log(e.response);
    dispatch(reStepThreeCodeAction(e.response));
  }
};

export const recoverSetPswTC =
  (otp, email, phone_number, password1, password2) => async (dispatch) => {
    try {
      if (phone_number[0] !== "+") {
        phone_number = "+" + phone_number;
      }
      const payload = await forgotPassSetPsw(
        otp,
        email,
        phone_number,
        password1,
        password2
      );
      // console.log("payload", payload);
      dispatch(reStepFourSetPswAction(payload));
    } catch (e) {
      console.log(e);
    }
  };

export default recoverReducer;
