import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import // useDispatch,
// useSelector
"react-redux";
import { Formik, Field } from "formik";
import Eye from "../../components/Eye/Eye";
import Popup from "../../components/Popup/Popup";
import //
"../../redux/reducers/RecoverReducer";
import {
  setPassReset,
  setResetVerify,
  setPassNew,
} from "../../api/services/authService";

const validate = (value) => {
  let errorMessage;
  if (!value) {
    errorMessage = "Required";
  }
  return errorMessage;
};

let string_for_popup = "Helloworld";

const Recover = () => {
  // useState
  const [step, setStep] = useState(1);
  const [userId, setUserId] = useState("");
  const [uuserIdError, setUserIdError] = useState(null);
  const [codeError, setCodeError] = useState(null);
  const [popupState, setPopupState] = useState(false);
  // eslint-disable-next-line
  const [passwordClick, setPasswordClick] = useState(false);
  const [passwordTwoClick, setPasswordTwoClick] = useState(false);
  // useHistory
  const history = useHistory();
  // useEffect
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const setPassResetHandler = async (user_id) => {
    try {
      const res = await setPassReset(user_id);
      if (res.status === 200) {
        setUserId(user_id);
        setUserIdError(null);
        string_for_popup = res.data;
        setPopupState(true);
      }
    } catch (e) {
      setUserIdError(e.response);
    }
  };
  // eslint-disable-next-line
  const setStepHandler = async (code) => {
    try {
      const data = { user_id: userId, code };
      const res = await setResetVerify(data);
      if (res.status === 200) {
        localStorage.setItem("secret", res.data.secret);
        setCodeError(null);
        setStep(2);
      }
    } catch (e) {
      setCodeError(e.response);
    }
  };
  const setPassNewHandler = async (password) => {
    const secret = localStorage.getItem("secret");
    const data = { user_id: userId, secret, new_password: password };
    const res = await setPassNew(data);
    if (res.status === 200) {
      history.push("/");
    }
  };

  return (
    <div className="container">
      <div className="row login__page__row">
        {step === 1 ? (
          <div className="col-lg-12 form__box two-forms">
            <h2 className="ta-center h2-recover-account">
              Recover Your Account
            </h2>
            <Formik
              initialValues={{
                userId: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                // same shape as initial values
                setPassResetHandler(values.userId);
                setSubmitting(false);
              }}
            >
              {({ errors, touched, handleSubmit, isSubmitting }) => (
                <form
                  className="login__form button-0 recover-account"
                  onSubmit={handleSubmit}
                >
                  <div className="form__center">
                    <div className="form-group">
                      <label htmlFor="userId" className="form-label">
                        User ID
                      </label>
                      <Field
                        name="userId"
                        type="text"
                        className={`form-control bigger-placeholder ${
                          errors.userId && touched.userId && errors.userId
                            ? "is-invalid"
                            : ""
                        }`}
                        id="userId"
                        placeholder="Enter user ID"
                        validate={validate}
                      />
                      {errors.userId && touched.userId && errors.userId ? (
                        <div className="invalid-feedback">{errors.userId}</div>
                      ) : null}
                    </div>
                    <div className="btnbox recover">
                      <button
                        type="submit"
                        className="btn btn-primary form__btn"
                        disabled={isSubmitting}
                      >
                        Send Code
                      </button>
                      <Popup open={popupState} data_to_display={string_for_popup} />
                      {(uuserIdError && uuserIdError?.status === 400) ||
                      uuserIdError?.status === 401 ||
                      uuserIdError?.status === 403 ||
                      uuserIdError?.status === 404 ? (
                        <span className="red">{uuserIdError.data}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </form>
              )}
            </Formik>

            <Formik
              initialValues={{
                code: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                // same shape as initial values
                setStepHandler(values.code);
                setSubmitting(false);
              }}
            >
              {({ errors, touched, handleSubmit, isSubmitting }) => (
                <form
                  className="login__form recover-account correct_login_form"
                  onSubmit={handleSubmit}
                >
                  <div className="form__center">
                    <div className="form-group">
                      <label htmlFor="code" className="form-label">
                        Code
                      </label>
                      <Field
                        name="code"
                        type="text"
                        className={`form-control bigger-placeholder ${
                          errors.code && touched.code && errors.code
                            ? "is-invalid"
                            : ""
                        }`}
                        id="code"
                        placeholder="Enter code"
                        validate={validate}
                      />
                      <div className="form-text">
                      </div>
                      {errors.code && touched.code && errors.code ? (
                        <div className="invalid-feedback">{errors.code}</div>
                      ) : null}
                    </div>
                    <div className="btnbox recover">
                      <button
                        type="submit"
                        className="btn btn-primary form__btn"
                        disabled={userId === ""}
                      >
                        Login
                      </button>
                      {(codeError && codeError?.status === 400) ||
                      codeError?.status === 401 ||
                      codeError?.status === 403 ||
                      codeError?.status === 404 ? (
                        <span className="red">{codeError.data}</span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        ) : step === 2 ? (
          <Formik
            initialValues={{ password1: "", password2: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.password1) {
                errors.password1 = "Required";
              } else if (values.password1.length < 6) {
                errors.password1 = "Too short code";
              } else if (
                !/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/g.test(values.password1)
              ) {
                errors.password1 = " ";
              }

              if (!values.password2) {
                errors.password2 = "Required";
              } else if (values.password2.length < 6) {
                errors.password2 = "Too long number";
              } else if (values.password2 !== values.password1) {
                errors.password2 = "Confirm Password is not correct";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setPassNewHandler(values.password2);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form
                className="col-lg-12 recover form-whith-eye form__box--recover recover-psw"
                onSubmit={handleSubmit}
              >
                <div>
                  <div className="recover__header">
                    <h2 className="with--arrow with--arrow--change h2-change-password">
                      Change Your Password
                    </h2>
                  </div>
                  <div className="recover__sms marginblabel with-margin-bottom">
                    <span>Create New Password</span>
                    <div className="with__eye with-form-text">
                      <input
                        type={passwordClick ? "text" : "password"}
                        name="password1"
                        placeholder="Enter new password"
                        className={`form-control with-form-text ${
                          errors.password1 &&
                          touched.password1 &&
                          errors.password1
                            ? "is-invalid"
                            : ""
                        }`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password1}
                      />
                      <div className="form-text">
                        The password must contain 8 digits, upper and lower
                        letters, and numbers
                      </div>
                      <Eye
                        passwordClick={passwordClick}
                        setPasswordClick={setPasswordClick}
                      />
                    </div>

                    {errors.password1 &&
                    touched.password1 &&
                    errors.password1 ? (
                      <div className="invalid-feedback max__w">
                        {errors.password1}
                      </div>
                    ) : null}
                  </div>
                  <div className="recover__sms marginblabel">
                    <span>Confirm New Password</span>
                    <div className="with__eye">
                      <input
                        type={passwordTwoClick ? "text" : "password"}
                        name="password2"
                        placeholder="Re - enter new password"
                        className={`form-control ${
                          errors.password2 &&
                          touched.password2 &&
                          errors.password2
                            ? "is-invalid"
                            : ""
                        }`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password2}
                      />
                      <Eye
                        passwordClick={passwordTwoClick}
                        setPasswordClick={setPasswordTwoClick}
                      />
                    </div>
                    {errors.password2 &&
                    touched.password2 &&
                    errors.password2 ? (
                      <div className="invalid-feedback recover">
                        {errors.password2}
                      </div>
                    ) : null}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary form__btn"
                  disabled={isSubmitting}
                >
                  Save Password
                </button>
              </form>
            )}
          </Formik>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Recover;
