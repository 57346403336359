import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Login } from './pages/login/Login';
import { Sms } from './pages/Sms/Sms';
import { checkAuthTC } from './redux/reducers/authReducer';
import Download from './pages/Download/Download';
import Header from './components/header/Header';
import Registration from './pages/Registration/Registration';
import Recover from './pages/Recover/Recover';
import { QrCode } from './pages/QrCode/QrCode';
import { UserId } from './pages/UserId/UserId';
import axios from 'axios';

export const App = () => {
  // useSelector
  const auth = useSelector(state => state.auth);

  // useDispatch
  const dispatch = useDispatch();
  // If IsAuth
  useEffect(() => {
    const checkVersion = async () => {
      const config = {
        headers: {
          ApiKey: 'y',
          'Content-Type': 'application/json',
        },
      };

      const responseApiVersion = await axios.get(
        'https://p.simhawk.tech/web_api/v1/version',
        config
      );

      const responseApiWebVersion = await axios.get(
        'https://p.simhawk.tech/web_api/v1/version',
        config
      );

      localStorage.setItem(
        'api-version',
        responseApiVersion.data.expected_version
      );
      localStorage.setItem(
        'api-web-version',
        responseApiWebVersion.data.expected_version
      );

      if (localStorage.getItem('token')) {
        dispatch(checkAuthTC());
      }
    };

    checkVersion();
  }, [dispatch, auth.isAuth]);

  useEffect(() => {}, [dispatch, auth.qrLink]);

  return (
    <Router>
      <div className="wrapper">
        <Header />
        <Switch>
          <Route exact path="/">
            {auth.isAuth &&
            auth.user?.registration_step === 5 &&
            auth.user?.show_user_id === true ? (
              <Download />
            ) : auth.isAuth &&
              auth.user?.registration_step === 5 &&
              auth.user?.show_user_id === false ? (
              <UserId />
            ) : auth.isAuth && auth.user?.registration_step < 5 ? (
              <Registration
                userInfo={auth.user}
                startStep={auth.user.registration_step}
              />
            ) : auth.isAuth === false && auth.qrLink !== null ? (
              <QrCode />
            ) : auth.isAuth === false && auth.qrScanned !== null ? (
              <QrCode />
            ) : auth.isAuth === false && auth.smsLink !== null ? (
              <Sms />
            ) : (
              <Login />
            )}
          </Route>
          <Route exact path="/download">
            {auth.isAuth &&
            auth.user?.registration_step === 5 &&
            auth.user?.show_user_id === true ? (
              <Download />
            ) : auth.isAuth &&
              auth.user?.registration_step === 5 &&
              auth.user?.show_user_id === false ? (
              <UserId />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/Recover">
            <Recover />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};
